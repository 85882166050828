/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    exclamation: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M7.002 11a1 1 0 112 0 1 1 0 01-2 0M7.1 4.995a.905.905 0 111.8 0l-.35 3.507a.553.553 0 01-1.1 0z"/>',
    },
});
